<template>
  <div class="secretary-list-view">
    <app-header icon="users" title="Secretárias"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-title"></div>
          <div class="card-header-buttons">
            <router-link :to="{ name: 'secretaries.create' }">
              <b-tooltip label="Nova Secretária">
                <b-button type="is-primary" size="is-small" icon-left="plus">
                  Adicionar
                </b-button>
              </b-tooltip>
            </router-link>
          </div>
        </header>

        <div class="card-content">
          <b-table
            @page-change="onPageChange"
            :data="data"
            :total="total"
            :per-page="15"
            :loading="isLoading"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="secretary" label="Nome">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="email" label="Email">
              {{ props.row.email }}
            </b-table-column>

            <b-table-column v-slot="props" label="Opções">
              <router-link
                :to="{
                  name: 'secretaries.edit',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="pencil-alt"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhuma secretária encontrada</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SecretariesService from '@/services/secretaries.service';

export default {
  data: () => ({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
  }),
  methods: {
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Secretária',
        message:
          'Tem certeza que deseja <b>excluir</b> esta secretária? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Secretária',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      SecretariesService.destroy(id)
        .then(() => {
          this.$buefy.snackbar.open('Secretária excluída com sucesso.');
          this.loadSecretaries();
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir a Secretária.',
              'error',
              4000
            );
          }
        });
    },
    loadSecretaries() {
      this.isLoading = true;

      SecretariesService.get({
        page: this.page,
        include: ['city'],
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.loadSecretaries();
    },
  },
  mounted() {
    this.loadSecretaries();
  },
};
</script>
